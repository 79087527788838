.items-menu.mb-1.collapse.show.navbar-collapse {
  display: flex !important;
}
.items-menu.collapse.navbar-collapse {
  display: none !important;
}
.navbar-vertical .navbar-collapse:before {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
a.dropdown-toggle.nav-link {
  cursor: pointer;
}
.dropdown-toggle::after {
  transition-delay: 0.5ms;
  transform: rotate(270deg);
}
.menu-open {
  .dropdown-toggle::after {
    transition-delay: 0.5ms;
    transform: rotate(0deg);
  }
}
.items-menu {
  padding: 0px !important;
  max-width: 250px !important;
  margin: 0px !important;
  .child-item {
    background-color: #eee;
    border-bottom: 0.5px solid #000;
    .child-menu-icon {
      width: 5px;
      height: 5px;
      background-color: #000;
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
    a.nav-link.active:before,
    a.nav-link.active:before {
      border: 0 !important;
    }
    a.nav-link.active {
      color: #000;
      .child-menu-icon {
        background-color: #5e72e4;
      }
    }
  }
}

nav {
  i.fa-arrow-left {
    transition: transform 0.3s;
  }
}

nav.collapsed-to-left {
  width: 60px !important;
  .logo-container {
    display: none;
  }
  i.fa-arrow-left {
    transform: rotate(180deg);
  }
  .nav-item span {
    display: none;
  }
  .navbar-nav{
    div.items-menu {
      visibility: hidden;
      height: 0;
    }
  }
}
.main-content.menu-collapsed-to-left {
  margin-left: 60px !important;
  .container {
    max-width: 1350px !important;
  }
}

nav.collapsed-to-left:hover {
  width: 250px !important;
  z-index: 9999;
  .logo-container {
    display: block;
  }
  .nav-item span {
    display: block;
  }
  .menu-open {
    div.items-menu {
      display: block !important;
    }
  }
  .navbar-nav{
    div.items-menu {
      visibility: visible;
      height: auto;
    }
  }
}
