.main_heading {
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 1px solid;
  border-color: #ecf0f3;
  display: inline-block;
}
.sub_heading {
  border-bottom: 1px solid;
  border-color: #ecf0f3;
  display: inline-block;
}
.inner_div {
  padding-right: 10%;
  padding-left: 10%;
  padding-bottom: 10%;
  padding-top: 5%;
}
.left{
    left: 5%;
}