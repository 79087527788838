.table td,
.table th {
  white-space: unset !important;
}

.dropdown.show .dropdown-toggle::after {
  transform: rotate(360deg);
}
.fs-11 {
  font-size: 11px;
}
.fw-500 {
  font-weight: 500;
}

.nav-item {
  a.active {
    i.ni,
    i.fas {
      // color: #2dce89 !important;
      color: #f5365c !important;
    }
  }
}
.navbar-vertical.navbar-expand-md {
  //   max-width: 300px;
  //   .items-menu{
  //     max-width: 300px !important;
  //   }
  li,
  a,
  span {
    font-size: 0.8rem !important;
  }
}

// .navbar-vertical.navbar-expand-md.fixed-left + .main-content{
//     margin-left: 300px;
// }

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
  border-color: #f5365c !important;
}

.items-menu .child-item a.nav-link.active .child-menu-icon {
  background-color: #f5365c !important;
}

.fixed-table {
  table-layout: fixed;
  width: 100%;
}

div.default-img {
  width: 100px;
  height: 120px;
  border-radius: 7px;
  background-color: aquamarine;
  text-align: center;
  i.fas {
    top: 50%;
    position: relative;
    transform: translateY(-60%);
  }
}

nav.position-sticky.sticky-top {
  z-index: 999;
}


.my-tab-link{
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.my-tab-link.active{
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}