.main {
  background-color: red;
}
.media_detail_container {
  display: flex;
}
.media_image {
  width: 125px;
  height: 150px;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
}
.artwork_image {
  width: 100%;
  min-width: 125px;
  height: 150px !important;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
}
.movie_name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #464e5f;
  margin-bottom: 0;
}
.relese_txt {
  font-size: 1.25rem;
  font-weight: 600;
  color: #464e5f;
  margin-bottom: 0;
}
.rating_containrer {
  display: flex;
}
// .edit_media_subheading:nth-child(even) {
//   background-color: hsl(0, 0%, 90%);
// }

.edit_media_subheading {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  .form-group {
    margin-bottom: 0;
  }
}
.edit_media_subheading_txt {
  width: 35%;
  font-size: 1.25rem;
  font-weight: 600;
  color: #464e5f;
  margin-left: 5px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.edit_media_input {
  width: 65%;
}
.tag_heading {
  width: 17%;
  font-size: 1.25rem;
  font-weight: 600;
  color: #464e5f;
  margin-left: 5px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.multivalue {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  padding: 0;
  div.cross-btn {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
  }

  div.cross-btn:hover {
    background-color: #ffbdad;
    color: #de350b;
  }
}

.media .dropdown,
.media .dropdown button,
.media .dropdown div {
  min-width: 100%;
}

.btn-w-100.dropdown {
  min-width: 100%;
  button,
  div {
    min-width: 100%;
  }
}

.add-media-list {
  img {
    width: 95px;
    height: 142px;
    border-radius: 7px;
  }
  div.d-img {
    width: 95px;
    height: 142px;
    border-radius: 7px;
    background-color: aquamarine;
    text-align: center;
    i.fas {
      top: 50%;
      position: relative;
      transform: translateY(-60%);
    }
  }
}

/* Example Styles for React Tags*/

.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 320px;
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  margin-top: 10px;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.crewImg {
  img {
    width: 80px;
    height: 80px;
    border-radius: 7px;
  }
  div.d-img {
    width: 80px;
    height: 80px;
    border-radius: 7px;
    background-color: aquamarine;
    text-align: center;
    i.fas {
      top: 50%;
      position: relative;
      transform: translateY(-60%);
    }
  }
}

// input tags
.ReactTags__selected {
  margin-left: -5px;
  .tag-wrapper {
    margin-bottom: 5px !important;
    background-color: hsl(0, 0%, 90%) !important;
    border: none !important;
    color: #000 !important;
    button.ReactTags__remove {
      color: #515f7f;
      border-radius: 2px;
      margin-left: 5px;
    }
    button.ReactTags__remove:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
  span.more-btn {
    background-color: #16c016 !important;
    color: #fff !important;
  }
}

.ReactTags__tagInput {
  flex-direction: row;
  display: flex;
  .tag-loader-spinner {
    width: 34px;
    height: 30px;
    margin-top: 10px;
    margin-left: 10px;
    border-width: 2px !important;
    border-color: #16c016 !important;
    border-right-color: transparent !important;
  }
}

input.ReactTags__tagInputField {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 5px;
}

div.ReactTags__suggestions {
  z-index: 999;
  max-height: 60vh;
  overflow-y: auto;
  border: 0.5px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  margin-left: 1px;
  margin-top: 42px;
  ul {
    padding: 0;
    margin: 0;
    li {
      padding-right: 10px;
    }
    li:hover {
      background-color: hsl(0, 0%, 90%) !important;
    }
  }
}

.add-person {
  div.d-img {
    width: 125px;
    height: 150px;
    border-radius: 7px;
    background-color: aquamarine;
    text-align: center;
    i.fas {
      top: 50%;
      position: relative;
      transform: translateY(-60%);
    }
  }
}


.close-plus-icon{
  position: absolute;
  z-index: 99;
  top: 2px;
  right: 2px;
  background-color: #fff;
  color: #de350b;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
}

.provider-form:nth-of-type(even){
  background-color: #ddd;
}

.provider-form:nth-of-type(odd){
  background-color: rgb(205, 205, 205);
}


