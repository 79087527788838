img.platform-img{
    max-width: 70px;
    min-width: 40px;
    max-height: 70px;
    min-height: 40px;
    box-sizing: content-box;
    border-radius: 7px;
}
.v-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
}

.search-icon-box{
    position: relative;
    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 20px;
        cursor: pointer;
    }
}

.add-rate{
    .dropdown-menu{
        max-height: 60vh;
        overflow-y: auto;
    }
}