.v-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
}
.custom-pagination{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    .pagination{
        margin-bottom: 0 !important;
        .page-item .page-link, .page-item span{
            border-radius: 0 !important;
            color: #3699ff;
        }
    }
    .dropdown button{
        border: none;
        box-shadow: none;
    }
    .pagination-btn{
        border: none;
        box-shadow: none;
        margin-left: 5px;
        margin-right: 5px;
    }
    button.active-page-btn{
        background-color: #3699ff;
        color: #fff;
    }
    .dropdown.show .dropdown-toggle::after{
        transform: rotate(360deg);
    }
}