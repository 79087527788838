#college {
  .active-tabs {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .tabs {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    padding: 10px;
  }
  Table {
    border-spacing: 0px;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
  }

  Input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 50%;
    padding: 10px;
    margin-right: 5px;
  }
}
