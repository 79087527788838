.custom-link {
  margin-top: 20px;
}
.search_div {
  margin-top: 30px;
}
.sort_date {
  //   background-color: darkgreen;
}
.download {
  // background-color: darkorange;
}
.download_button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 600;
  float: right;
}
.sort_by_date {
  // background-color: darkorange;
  margin-left: 8%;
}

.nav_link {
  color: rgba(0, 0, 0, 0.87);
  // background-color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.view_button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
}
.table_heading {
  color: black;
  font-weight: bold !important;
}
// .modal{
//    display: 'block';
//    width: 700;
//    height: 400;
//    // padding: 30;
//    // background-color: blue;
// }
.modal_button {
  align-self: center;
  background-color: red;
}
.media {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.search_sort {
  // background-color:red;
  margin-top: 20px;
}
.filters {
  border-width: 1px;
  border-color: black;
  height: auto;
}
.filters_1strow {
  flex-direction: row;
  justify-content: space-around;
  height: auto;
}
.filter_drop {
  width: 100% !important;
  background-color: green;
}

.btn-w-100 {
  width: 100%;
  button {
    width: 100%;
  }
}
.btn-h-100 {
  height: 100%;
}
.m-0 {
  margin: 0;
}
.mt-15 {
  margin-top: 15px;
}
.text-right {
  text-align: right;
}
.ml-22 {
  margin-left: 22%;
}
.mt-20 {
  margin-top: 20px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.mt-10 {
  margin-top: 10px;
}
.ml-19 {
  margin-left: 19.7%;
}
.curated-form {
  background-color: #e7f1fe;
  padding: 20px;
  border-radius: 5px;
}
.radio-button {
  margin-left: 2px;
  position: relative !important;
}
.mt-2 {
  margin-top: 2px;
}
.notification-sort {
  margin-left: 21.5%;
}
.table {
  overflow-y: auto;
  table {
    thead {
      border-bottom: 1.5px solid #ecf0f3;
      tr:first-child {
        border-top: 1.5px solid #ecf0f3;
        border-bottom: 1.5px solid #ecf0f3;
      }
      th {
        border-right: 1.5px solid #ecf0f3;
        border-left: 1.5px solid #ecf0f3;
      }
    }
    tbody {
      border-top: 1.5px solid #000;
    }
  }
}
.input-type {
  width: 50px;
  margin-left: 45%;
}
.media-count {
  margin: 0;
}
.success-btn {
  text-align: center;
}
.btn-width {
  // width: 20%;
  // right: 8px;
  // left: 5px;
}
.show-form {
  background-color: #e7f1fe;
  padding: 10px;
  border-radius: 10px;
  width: 61%;
}
.show-form-input {
  flex: 1;
  max-width: none;
}
.show-form-delete {
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
}
.heading {
  border-bottom: 1px solid;
  border-color: #ecf0f3;
  display: inline-block;
  color: #f6b26b;
}
.label-txt {
  padding-top: 0px;
}
.review_table {
  overflow-y: auto;
}
.marg-bot-minus{
  margin-bottom: -10px;
}
.checkbox{
  position: static !important;

}
.reason-column{
  margin-top: 10px;
  right: 6%;
}
.modal_body{
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
}