.media_type_list_box{
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 500px;
    overflow-y: auto;
    position: absolute;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    z-index: 999;
    // background-color: #fff;
    width: 100%;
    max-width: 470px;
    background-color: #eee;
    .media_type_list{
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .media_type_list:hover{
        background-color: #11cdef;
    }
}

.pointer{
    cursor: pointer;
}