.vertical-middle td {
    vertical-align: middle;
  }
  // .category-list {
  //   max-height: 350px;
  //   overflow-y: auto;
  //   margin-top: 10px;
  //   background-color: white;
  //   padding-left: 5px;
  //   padding-right: 5px;
  //   border-radius: 10px;
  
  //   .category_img {
  //     max-width: 100%;
  //     width: 140px;
  //     height: 160px;
  //     margin-top: 7px;
  //   }
  // }
  
  .category-list {
    max-height: 60vh;
    width: 100%;
    z-index: 99;
    overflow-y: auto;
    top: 50px;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    border: 0.5px solid #ddd;
    padding-left: 10px;
    padding-right: 10px;
    .media-item {
      border-bottom: 0.5px solid #ddd;
      padding-bottom: 10px;
      input{
        cursor: pointer;
      }
    }
    .media-item:hover {
      background-color: rgb(241, 238, 238);
    }
    img {
      width: 100px;
      height: 120px;
      border-radius: 7px;
      margin-top: 10px;
    }
    div.d-img {
      width: 100px;
      height: 120px;
      border-radius: 7px;
      background-color: aquamarine;
      text-align: center;
      margin-top: 10px;
      i.fas {
        top: 50%;
        position: relative;
        transform: translateY(-60%);
      }
    }
  }
  
  .category_img {
    img {
      width: 80px;
      height: 80px;
      border-radius: 7px;
      margin-top: 10px;
    }
    div.d-img {
      width: 80px;
      height: 80px;
      border-radius: 7px;
      background-color: aquamarine;
      text-align: center;
      margin-top: 10px;
      i.fas {
        top: 50%;
        position: relative;
        transform: translateY(-60%);
      }
    }
  }
  